<template>
  <div class="report-list report-list--double">
    <h2 class="title title--indent">Проверенные карты</h2>
    <div v-if="logs.length" class="report-list__content">
      <div
        v-for="log in logs"
        :key="log.id"
        class="report-list__item"
      >
        <div class="report-list__point">
          <div class="report-list__name">Номер карты:</div>
          <div class="report-list__value">{{ log.number }}</div>
        </div>
        <div class="report-list__point">
          <div class="report-list__name">Дата проверки:</div>
          <div class="report-list__value">{{ log.created_at }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VerifyLog',
  data() {
    return {
      logs: [],
    };
  },
  mounted() {
    this.fetchLogs();
  },
  methods: {
    fetchLogs() {
      this.$store.dispatch('partner/report/GET_VERIFY_LOG')
        .then(response => {
          this.logs = response.data.results;
        })
        .catch(error => {
          this.$notify({
            type: 'warn',
            title: 'Внимание!',
            text: `Ошибка: ${error.message}`
          });
        });
    },
  },
};
</script>